<template>
  <div class="">
    <div class="mb-3">
      <h3 class="mb-2">
        about
      </h3>
      {{ entity.about }}
    </div>

    <div class="mb-3">
      <h3 class="mb-2">
        parent
      </h3>
      {{ parentName }}

      <b-checkbox
        v-model="entity.is_main_branch"
        disabled
        class="mt-1"
      >
        Main Branch
      </b-checkbox>
    </div>

    <b-row class="mb-2">
      <!-- Address -->
      <b-col>
        <h4 class="mb-2">
          address
        </h4>
        <ul class="list-unstyled">
          <li class="mb-1">
            <span class="mr-2">country:</span>
            <span>{{ entity.address[0].country }}</span></li>
          <li class="mb-1">
            <span class="mr-2">street:</span>
            <span>{{ entity.address[0].street }}</span></li>
          <li class="mb-1">
            <span class="mr-2">city:</span>
            <span>{{ entity.address[0].city }}</span></li>
          <li class="mb-1">
            <span class="mr-2">state:</span>
            <span>{{ entity.address[0].state }}</span></li>
          <li class="mb-1">
            <span class="mr-2">zip code:</span>
            <span>{{ entity.address[0].postal_code }}</span></li>
          <li class="mb-1">
            <span class="mr-2">latitude:</span>
            <span>{{ entity.location.coordinates[0] }}</span></li>
          <li class="mb-1">
            <span class="mr-2">longitude:</span>
            <span>{{ entity.location.coordinates[1] }}</span></li>
        </ul>
      </b-col>

      <!-- Contact -->
      <b-col>
        <h4 class="mb-2">
          contact
        </h4>

        <ul class="list-unstyled">
          <li
            v-if="entity.contact_name"
            class="mb-1"
          >
            <span class="mr-2">Contact Person:</span>
            <span>{{ entity.contact_name }}</span>
          </li>
          <li class="mb-1">
            <span class="mr-2">Email:</span>
            <span>{{ entity.email }}</span>
          </li>
          <li class="mb-1">
            <span class="mr-2">Phone Number:</span>
            <span>{{ entity.phone_number }}</span>
          </li>
          <li class="mb-1">
            <span class="mr-2">Fax:</span>
            <span>{{ entity.fax_number }}</span>
          </li>
        </ul>
      </b-col>
    </b-row>

    <!-- Badges -->
    <div
      v-if="entity.classificationsNames.length"
      class="mb-2"
    >
      <h4 class="mb-2">
        classifications
      </h4>
      <b-badge
        v-for="classification in entity.classificationsNames"
        :key="classification"
        pill
        variant="light-primary"
        class="p-1 mr-1"
      >
        {{ classification }}
      </b-badge>
    </div>

    <div
      v-if="entity.subClassificationsNames.length"
      class="mb-2"
    >
      <h4 class="mb-2">
        sub classifications
      </h4>
      <b-badge
        v-for="subclass in entity.subClassificationsNames"
        :key="subclass"
        pill
        variant="light-primary"
        class="p-1 mr-1"
      >
        {{ subclass }}
      </b-badge>
    </div>

    <div
      v-if="entity.facilities.length"
      class="mb-2"
    >
      <h4 class="mb-2">
        facilities
      </h4>
      <b-badge
        v-for="facility in entity.facilities"
        :key="facility.id"
        pill
        variant="light-primary"
        class="p-1 mr-1"
      >
        {{ facility.name }}
      </b-badge>
    </div>

    <div
      v-if="entity.services.length"
      class="mb-2"
    >
      <h4 class="mb-2">
        services
      </h4>
      <b-badge
        v-for="service in entity.services"
        :key="service.id"
        pill
        variant="light-primary"
        class="p-1 mr-1"
      >
        {{ service.name }}
      </b-badge>
    </div>

    <div
      v-if="entity.programs.length"
      class="mb-2"
    >
      <h4 class="mb-2">
        programs
      </h4>
      <b-badge
        v-for="program in entity.programs"
        :key="program.id"
        pill
        variant="light-primary"
        class="p-1 mr-1"
      >
        {{ program.name }}
      </b-badge>
    </div>

    <div
      v-if="entity.products.length"
      class="mb-2"
    >
      <h4 class="mb-2">
        products
      </h4>
      <b-badge
        v-for="product in entity.products"
        :key="product.id"
        pill
        variant="light-primary"
        class="p-1 mr-1"
      >
        {{ product.name }}
      </b-badge>
    </div>

    <div
      v-if="entity.donationCategoriesNames.length"
      class="mb-2"
    >
      <h4 class="mb-2">
        donation categories
      </h4>
      <b-badge
        v-for="category in entity.donationCategoriesNames"
        :key="category"
        pill
        variant="light-primary"
        class="p-1 mr-1"
      >
        {{ category }}
      </b-badge>
    </div>

    <div
      v-if="entity.languagesNames.length"
      class="mb-2"
    >
      <h4 class="mb-2">
        languages
      </h4>
      <b-badge
        v-for="language in entity.languagesNames"
        :key="language"
        pill
        variant="light-primary"
        class="p-1 mr-1"
      >
        {{ language }}
      </b-badge>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OrgDetails',
  props: { entity: { type: Object, default: () => {} } },
  computed: {
    parentName() {
      return this.entity.parent?.name
    },
  },
}
</script>
<style lang="scss">

</style>
