<template>
  <div :style="cssVars">
    <!-- Cover -->
    <div
      v-if="entity.cover"
      class="cover"
    />

    <!-- Logo -->
    <div>
      <b-avatar
        :src="entity.logo"
        :text="avatarText(entity.name)"
        size="104px"
        rounded
        :class="{'logo-avatar':entity.cover, 'logo-border':true}"
      />
    </div>

    <!-- Entity Name -->
    <div
      style="margin-top:6.8em"
      class="mb-2"
      :class="{'entity-name':entity.cover,'mt-2':!entity.cover}"
    >
      <h2 class="font-weight-bold">
        {{ entity.name }}
      </h2>
      <span class="font-weight-light">{{ entity.email }}</span>
    </div>

    <!-- Header -->
    <div class="text-right">
      <!-- Buttons -->
      <back
        variant="outline-primary"
        class="mr-1 "
      />

      <b-button
        v-if="$can('update','EntitiesController')"
        variant="primary"
        :to="{name:editRoute, params:{id:$route.params.id||$store.getters['mainEntity/getEntityId']} }"
      >
        Edit
      </b-button>
    </div>
  </div>
</template>
<script>
import { avatarText } from '@core/utils/filter'
import Back from '../FormInputs/Back.vue'

export default {
  name: 'EntityBanner',
  components: { Back },
  props: {
    entity: { type: Object, default: () => {} },
    editRoute: { type: String, default: '' },
  },
  computed: {
    cssVars() {
      return {
        '--bg-image': `url(${this.entity.cover})`,
      }
    },
  },
  setup() {
    return { avatarText }
  },
}
</script>
<style lang="scss">
// Images
.cover {
  background-image: var(--bg-image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 17em;
  border-radius: 5px 5px 0 0;
  position: relative;
}

//Logo
.logo-avatar {
    position:absolute;
    top:29em;
    left:4em;
}

.logo-border {
    border:4px solid #fff
}

.entity-name {
  margin:5em 0 0 1em;
}
</style>
