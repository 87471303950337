<template>
  <div>
    <h4 class="mb-2">
      contact
    </h4>

    <ul class="list-unstyled">
      <li class="mb-1">
        <feather-icon
          size="20"
          class="mr-2 text-primary"
          icon="FacebookIcon"
        />
        <span class="mr-2">facebook:</span>
        <span>{{ entity.social_facebook }}</span>
      </li>
      <li class="mb-1">
        <feather-icon
          size="20"
          class="mr-2 text-primary"
          icon="YoutubeIcon"
        />
        <span class="mr-2">youtube:</span>
        <span>{{ entity.social_youtube }}</span>
      </li>
      <li class="mb-1">
        <feather-icon
          size="20"
          class="mr-2 text-primary"
          icon="TwitterIcon"
        />
        <span class="mr-2">twitter:</span>
        <span>{{ entity.social_twitter }}</span>
      </li>
      <li class="mb-1">
        <feather-icon
          size="20"
          class="mr-2 text-primary"
          icon="InstagramIcon"
        />
        <span class="mr-2">instagram:</span>
        <span>{{ entity.social_instagram }}</span>
      </li>
      <li class="mb-1">
        <feather-icon
          size="20"
          class="mr-2 text-primary"
          icon="GlobeIcon"
        />
        <span class="mr-2">website:</span>
        <span>{{ entity.website }}</span>
      </li>
    </ul>

    <div class="mt-3">
      <h4 class="mb-2">
        Tags
      </h4>
      <b-badge
        v-for="tag in entity.tagsNames"
        :key="tag"
        pill
        variant="light-primary"
        class="p-1 mr-1"
      >
        {{ tag }}
      </b-badge>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EntitySocial',
  props: { entity: { type: Object, default: () => {} } },
}
</script>
<style lang="scss">

</style>
